<template>
  <div>
                <v-text-field
                  v-model="url"
                ></v-text-field>
                <v-text-field
                  v-model="id"
                ></v-text-field>
                 <v-text-field
                 label="frind-id"
                  v-model="fbId"
                ></v-text-field>
    <button class="button" @click="logInWithFacebook"> Login with Facebook</button>
    <button class="button" @click="logOutWithFacebook"> LogOut with Facebook</button>
    <button class="button" @click="getFriendList"> List Freind</button>
    <button class="button" @click="sendMessage"> Send Message</button>
  </div>
</template>
<script>
export default {
  name: 'facebookLogin',
  data: () => ({
    url: '',
    id: '',
    fbId: '',
    allPageData: [],
  }),
  async mounted() {
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    await this.initFacebook()
  },
  methods: {
    async getFriendList() {
      await FB.api(this.url, function (response) {
        console.log(response, 'list friend')
        if (response && !response.error) {
          /* handle the result */
        }
      })
    },

    async getListPage(userId) {
      await FB.api(`/${userId}/accounts`, function (response) {
        console.log(response, 'list friend')
        if (response && !response.error) {
          /* handle the result */
        }
      })
    },
    async sendMessage() {
      await this.$store.dispatch('eOrdering/sendMessageFB', {
        url: this.url,
        messaging_type: 'String',
        recipient: {
          id: `${this.fbId}`,
        },
        message: {
          text: 'hello, world!',
        },
      })
    },
    // '100078421364587'
    async logOutWithFacebook() {
      // await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      // await this.initFacebook();
      await window.FB.logout(function (response) {
        console.log(response, 'ini log out')
        if (response) {
          alert('You are logged in &amp; cookie set!')
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
        } else {
          alert('User cancelled login or did not fully authorize.')
        }
      })
      return false
    },
    async logInWithFacebook() {
      // let listPage = []
      await window.FB.login(
        async function (response) {
          // console.log(response, 'ini log in')
          if (response.authResponse) {
            // let listPage = await this.getListPage(response.authResponse.userID)
            // await FB.api(`/${response.authResponse.userID}/accounts`, function (response) {
            //   // console.log(response, 'list Page')
            //   if (response && !response.error) {
            //     /* handle the result */
            //     // return response.data
            //     // listPage = response.data

            //   }
            // })
            await FB.api(`/${response.authResponse.userID}/accounts`, function (res) {
              // console.log(response, 'list friend')
              if (res && !res.error) {
                this.allPageData = res.data
                /* handle the result */
                console.log(this.allPageData, 'ini list page')
                var subscribeAppFields = [
                  'messages',
                  'messaging_optins',
                  'messaging_postbacks',
                  'messaging_handovers',
                  'standby',
                ]

                this.allPageData.forEach(async element => {
                  FB.api(
                    '/' + element.id + '/subscribed_apps',
                    'POST',
                    {
                      subscribed_fields: subscribeAppFields,
                      access_token: element.access_token,
                    },
                    function (subres) {
                      console.log(subres, 'set subcribe')
                    },
                  )
                })
              }
            })

            alert('You are logged in &amp; cookie set!')
            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.
          } else {
            alert('User cancelled login or did not fully authorize.')
          }
        },
        { scope: 'pages_messaging,pages_read_engagement,pages_manage_metadata,pages_show_list' },
      )
      // console.log(serve)
      // this.allPageData = listPage
      // console.log(this.allPageData, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      //pages_read_engagement pages_read_user_content pages_show_list
      return false
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '987230478837936', //You will need to change this
          autoLogAppEvents: true,
          xfbml: true,
          cookie: true, // This is important, it's not enabled by default
          version: 'v13.0',
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
  },
}
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
